<template>
  <el-container>
    <el-header style="height: 100px">
    <el-form ref="form" :model="form" label-width="80px">
      <el-row>
        <el-col :span="3">
          <el-form-item>
            <el-input v-model="form.kami" placeholder="卡密">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item>
            <el-input v-model="form.req" placeholder="请求内容">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item>
            <el-input v-model="form.ip" placeholder="ip">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item>
          <el-select v-model="form.type" placeholder="类型">
            <el-option label="读书" value="read"></el-option>
            <el-option label="搜索" value="search"></el-option>
            <el-option label="链接" value="link"></el-option>
            <el-option label="书城-读书" value="book_read"></el-option>
            <el-option label="书城-搜索" value="book_search"></el-option>
            <el-option label="书城-链接" value="book_link"></el-option>
          </el-select>
        </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item>
            <el-col :span="11">
              <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="开始时间" v-model="form.startTime" style="width: 100%;"></el-date-picker>
            </el-col>
            <el-col class="line" :span="2">-</el-col>
            <el-col :span="11">
              <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="结束时间" v-model="form.endTime" style="width: 100%;"></el-date-picker>
            </el-col>
          </el-form-item>
        </el-col>
      </el-row>
      <el-button style="float: right" @click="searchKey()" type="primary">搜索</el-button>
    </el-form>
    </el-header>
    <el-main>
    <el-table
        :data="tableData"
        style="width: 100%">
      <el-table-column
          prop="type"
          label="类型"
          width="180">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ formatType(scope.row.type)}}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="kami"
          label="卡密"
          width="180">
      </el-table-column>
      <el-table-column
          prop="ip"
          label="地址">
      </el-table-column>
      <el-table-column
          prop="req"
          label="请求内容">
      </el-table-column>
      <el-table-column
          prop="create_time"
          label="请求时间">
      </el-table-column>
    </el-table>
    </el-main>
    <el-footer>
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[100, 200, 300, 400]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
    </el-footer>
  </el-container>
</template>
<style scoped>

</style>
<script>

import axios from 'axios'
import moment from 'moment';

export default {
  data() {
    return {
      total:0,
      form: {
        kami: '',
        ip: '',
        startTime: '',
        endTime: '',
        type: '',
        req: ''
      },
      searchForm: {
        searchKey: ''
      },
      tableData: [
        /* Your data array */
      ],
      currentPage: 1,
      pageSize: 100,
    };
  },
  async  mounted() {
    const response = await axios.get('http://8.138.94.192:8991/api/list')
    this.tableData = response.data.data
    this.total = response.data.total
    console.log(response)
  },
  methods: {
    formatType(type){
     switch (type){
       case 'search':
         return '搜索'
       case 'link':
         return '链接搜索'
       case 'read':
         return '读书'
       case 'book_search':
         return '书城-搜索'
       case 'book_read':
         return '书城-读书'
       case 'book_link':
         return '书城-链接'
       default:
         return '未知'
     }
    },
    format(data){
      if(data){
        return moment(data).format('YYYY-MM-DD HH:mm:ss')
      }
    },
    async searchKey() {
      const response = await axios.get('http://8.138.94.192:8991/api/list?page='+this.currentPage+'&per_page='+this.pageSize
      +'&kami='+this.form.kami+'&ip='+this.form.ip+'&start_time='+this.form.startTime+'&end_time='+this.form.endTime+'&type='+this.form.type+'&req='+this.form.req)
      this.tableData = response.data.data
      this.total = response.data.total
    },
    async handleSizeChange(val) {
      this.pageSize = val;
      const response = await axios.get('http://8.138.94.192:8991/api/list?page='+this.currentPage+'&per_page='+this.pageSize
          +'&kami='+this.form.kami+'&ip='+this.form.ip+'&start_time='+this.form.startTime+'&end_time='+this.form.endTime+'&type='+this.form.type+'&req='+this.form.req)
      this.tableData = response.data.data
      this.total = response.data.total
    },
    async handleCurrentChange(val) {
      this.currentPage = val;
      const response = await axios.get('http://8.138.94.192:8991/api/list?page='+this.currentPage+'&per_page='+this.pageSize
          +'&kami='+this.form.kami+'&ip='+this.form.ip+'&start_time='+this.form.startTime+'&end_time='+this.form.endTime+'&type='+this.form.type+'&req='+this.form.req)
      this.tableData = response.data.data
      this.total = response.data.total
    }
  }
}
</script>
